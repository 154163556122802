body {
  font-family: 'Arial', sans-serif;
  margin: 0px;
  /* justify-content: center; */
}

h1 {
  color: #333;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
