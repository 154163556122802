.App {
  /* text-align: center; */
  align-items: center;
}

.App-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  /* background-color: lightblue; */
  /* padding: 5px; */
  color: black;
  margin-bottom: 20px;
}

.title {
  display: flex;
  flex: 3;
  margin-left: 20px;
  color: white;
}

.info {
  position: right;
  margin-right: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.user-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.App-body {
  padding: 10px;
}

.Upload {
  max-width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-stack {
  width: 140px
}

.Upload-vertical {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}

.Upload-vertical > *:not(:last-child) {
  margin-bottom: 10px;
}

.null-cell {
  background-color: #F0F0F0;
}

.Upload-status {
  display: flex;
  height: 60px;
  justify-content: center;
  align-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-wrapper {
  width: 100%;
  position: relative;
}

.table {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 5px;
  width: 100%;
  margin: auto;
  overflow-x: auto;
}

.table td {
  border-top: 0.5px solid #ddd;
  overflow: hidden;
  overflow-x: auto;
}

.city-col {
  width: 4%;
  min-width: 80px;
}

.question-col {
  width: 20%;
  min-width: 200px;
}

.fact-col {
  width: 15%;
  min-width: 200px;
}

.url-col {
  width: 15%;
  min-width: 100px;
}

.note-col {
  width: 10%;
  min-width: 100px;
}

.question-col-fact {
  width: 35%;
  min-width: 200px;
}

.fact-col-fact {
  width: 35%;
  min-width: 200px;
}


.thread-trivia th {
  color: white;
  background-color: rgb(245, 81, 196, 1);
  opacity: 0.8;
  /* background-color: ; */
}

.thread-fact th {
  color: white;
  background-color: rgba(51, 180, 227, 1);
}